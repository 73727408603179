import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/home/index.vue'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',//模型库
    name: 'home',
    component: HomeView
  },
  {
    path: '/company/profile',
    name: 'companyProfile',
    component: () => import('../views/aboutUs/index.vue')
  },
  {
    path: '/modelDetail/:id',// 模型详情
    name: 'modelDetail',
    component: () => import('../views/home/modelDetail.vue')
  },
  {
    path: '/modelColumn',//模型专栏
    name: 'modelColumn',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/modelColumn/modelColumn.vue')
  },
  {
    path: '/productLibrary',//产品库
    name: 'productLibrary',
    component: () => import('../views/productLibrary/productLibrary.vue')
  },
  {
    path: '/productLibrary/list',//产品库
    name: 'productList',
    component: () => import('../views/productLibrary/productList.vue')
  },
  {
    path: '/productDetail',//产品详情
    name: 'productDetail',
    component: () => import('../views/productLibrary/productDetail.vue')
  },
  {
    path: '/shop',//产品详情
    name: 'shop',
    component: () => import('../views/shop/index.vue')
  },
  {
    path: '/shoppingCart',//购物车
    name: 'shoppingCart',
    component: () => import('../views/shoppingCart/index.vue')
  },
  {
    path: '/evaluate',//评论
    name: 'evaluate',
    component: () => import('../views/userInfo/evaluate.vue')
  },
  {
    path: '/logistics',//物流
    name: 'logistics',
    component: () => import('../views/userInfo/logistics.vue')
  },
  
  {
    path: '/userInfo',//个人中心
    name: 'userInfo',
    redirect: '/userInfo/index',
    component: () => import('../views/userInfo/index.vue'),
    children: [
      {
        path: '/userInfo/index',//首页
        component: () => import('../components/userInfo/index.vue')
      },
      {
        path: '/userInfo/model',//模型页
        name: 'model',
        component: () => import('../components/userInfo/model.vue')
      },
      {
        path: '/userInfo/order',//订单列表
        name: 'order',
        component: () => import('../components/userInfo/order.vue')
      },
      {
        path: '/userInfo/orderdetail',//订单详情
        name: 'orderDetail',
        component: () => import('../components/userInfo/orderdetail.vue')
      },
      {
        path: '/userInfo/afterDetail',//售后详情
        name: 'afterDetail',
        component: () => import('../components/userInfo/afterDetail.vue')
      },
      {
        path: '/userInfo/section',//切片页
        name: 'section',
        component: () => import('../components/userInfo/section.vue')
      },
      {
        path: '/userInfo/account',//账户页
        name: 'account',
        component: () => import('../components/userInfo/account.vue')
      },
      {
        path: '/userInfo/storage',//存储空间
        name: 'storage',
        component: () => import('../components/userInfo/storage.vue')
      },
      {
        path: '/userInfo/collect',//收藏
        name: 'collect',
        component: () => import('../components/userInfo/collect.vue')
      },
      {
        path: '/userInfo/evaluate',//评价
        name: 'evaluate',
        component: () => import('../components/userInfo/evaluate.vue')
      },
    ]
  },
  {
    path: '/user',//个人资料
    name: 'user',
    redirect: '/user/info',
    component: () => import('../views/userInfo/userInfo.vue'),
    children: [
      {
        path: '/user/info',//个人资料首页
        name: 'info',
        component: () => import('../components/userInfo/info.vue'),
      },
      {
        path: '/user/setAccount',//设置账号
        name: 'setAccount',
        component: () => import('../components/userInfo/setAccount.vue'),

      },
      {
        path: '/user/setPassword',//修改密码
        name: 'setPassword',
        component: () => import('../components/userInfo/setPassword.vue'),

      },
    ],
  },
  {
    path: '/workbenches',//工作台
    name: 'workbenches',
    component: () => import('../views/workbenches/index.vue'),
  },
  {
    path: '/workbenches/createTask',//创建任务
    name: 'createTask',
    component: () => import('../views/workbenches/createTask.vue'),
  },
  {
    path: '/workbenches/history',//历史任务
    name: 'history',
    component: () => import('../views/workbenches/history02.vue'),
  },
  {
    path: '/workbenches/historyDetail',//历史任务详情
    name: 'history',
    component: () => import('../views/workbenches/historyDetail.vue'),
  },
  {
    path: '/workbenches/deviceDetail',// 设备详情
    name: 'history',
    component: () => import('../views/workbenches/device_detail.vue'),
  },
  {
    path: '/paymentIndex',//结算
    name: 'paymentIndex',
    component: () => import('../views/payment/index.vue')
  },
  {
    path: '/paymentDetail',//确认订单
    name: 'paymentDetail',
    component: () => import('../views/payment/detail.vue')
  },
  {
    path: '/paymentSuccess',// 支付成功
    name: 'paymentSuccess',
    component: () => import('../views/payment/success.vue')
  },
  {
    path: '/pay/payMent/paypal/success',// 支付成功
    name: 'paymentPaypalSuccess',
    component: () => import('../views/payment/success.vue')
  },
  {
    path: '/pay/payMent/paypal/cancel',// 支付成功
    name: 'paymentPaypalCancel',
    component: () => import('../views/payment/fail.vue')
  },
  {
    path: '/columnDetail',//专栏详情
    name: 'columnDetail',
    component: () => import('../views/home/columnDetail.vue')
  },
  {
    path: '/aboutUs',//公司介绍
    name: 'aboutUs',
    component: () => import('../views/aboutUs/aboutUs.vue')
  },
  {
    path: '/joinUs',//加入我们
    name: 'joinUs',
    component: () => import('../views/aboutUs/joinUs.vue')
  },
  {
    path: '/helpCenter',//帮助中心/新手指南
    name: 'helpCenter',
    component: () => import('../views/serviceSupport/helpCenter.vue')
  },
  {
    path: '/feedback',//意见反馈
    name: 'feedback',
    component: () => import('../views/serviceSupport/feedback.vue')
  },
  {
    path: '/myFeedback',//意见反馈
    name: 'myFeedback',
    component: () => import('../views/serviceSupport/myFeedback.vue')
  },
  {
    path: '/agreement',//协议等等..
    name: 'agreement',
    component: () => import('../views/serviceStatement/agreement.vue')
  },
  {
    path: '/userBuyVip', // 购买会员
    name: 'userBuyVip',
    component: () => import('../views/userInfo/userBuyVip.vue')
  },
  {
    path: '/userBuyStorage', // 购买存储空间
    name: 'userBuyStorage',
    component: () => import('../views/userInfo/userBuyStorage.vue')
  },
  {
    path: '/shiPaiTuDetail', // 实拍效果详情
    name: 'shiPaiTuDetail',
    component: () => import('../views/home/shiPaiTuDetail.vue')
  },
  {
    path: '/modelLib', // 模型库
    name: 'modelLib',
    component: () => import('../views/home/modelLib.vue')
  },
  {
    path: '/callbackqq', // qq登录回调页面
    name: 'callbackqq',
    redirect: '/'
  },
  {
    path: '/changePassword', // 忘记密码
    name: 'changePassword',
    component: () => import('../views/home/changePassword.vue')
  },
  {
    path: '/slicePreview', // 切片预览
    name: 'slicePreview',
    component: () => import('../components/qie_pian_yu_lan.vue')
  },



  {
    path: '/agreementPrivacy', // 协议
    name: 'agreementPrivacy',
    component: () => import('@/views/agreementPrivacy.vue')
  },
  {
    path: '/h5/download_app', // 协议
    name: 'DownloadApp',
    component: () => import('@/views/h5/download_app.vue')
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  //页面跳转显示在顶部
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes
})

export default router
